<template>
  <product title="Amazfit 米动健康手环 黄山1号版"
    :swiper-data="swiperData"
    :slogan="slogan"
    price="399"
    :colors="colors"
    :relatived-products="relativedProducts"
    :detail-images="detailImages"
    :param-images="paramImages"
    buy-link="https://j.youzan.com/JlbjKh"
  ></product>
</template>

<script>
import { CDN_URL } from '@/config'
import Product from '../Product'

export default {
  name: 'health-band-chip',
  components: {
    Product
  },
  data () {
    return {
      slogan: '7 x 24 小时不间断精准心率监测 | ECG 高精度心电测量<br/>AI本地甄别心律不齐(含房颤) | 亲友关注',
      colors: [
        '黑色'
      ],
      swiperData: [
        [
          CDN_URL + '/images/product/health-band-chip/swiper/1.png',
          CDN_URL + '/images/product/health-band-chip/swiper/2.png',
          CDN_URL + '/images/product/health-band-chip/swiper/3.png',
          CDN_URL + '/images/product/health-band-chip/swiper/4.png'
        ]
      ],
      relativedProducts: [],
      detailImages: [
        'https://site-cdn.huami.com/files/amazfit/mobile/health-band-chip/01.jpg',
        CDN_URL + '/images/product/health-band-chip/detail/02.jpg',
        CDN_URL + '/images/product/health-band-chip/detail/03.jpg',
        CDN_URL + '/images/product/health-band-chip/detail/04.jpg',
        // CDN_URL + '/images/product/health-band-chip/detail/05.jpg',
        // CDN_URL + '/images/product/health-band-chip/detail/06.jpg',
        CDN_URL + '/images/product/health-band-chip/detail/07.jpg'
      ],
      paramImages: [
        CDN_URL + '/images/product/health-band-chip/detail/08.jpg'
      ]
    }
  },
  methods: {

  }
}
</script>

<style>
</style>
